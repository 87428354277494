import React from "react";
import styled from "styled-components";
import { graphql, PageProps } from "gatsby";

import { Widget } from "@typeform/embed-react";
import { Query, SanityBlogInnerConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import { colors, fontSizes, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, NoteComponent, Image, BackOrNextButton } from "@global";
import {
  isSanityBlockContent,
  isSanityImageWithMeta,
  isSanityNoteComponent,
  isSanityTypeForm,
} from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";

const BlogHero = styled(Container)<{ color?: string }>`
  width: 100%;
  flex-direction: column;
  height: 500px;
  background-color: ${props => (props.color ? props.color : colors.flightBlue)};
`;

const ImageContainer = styled(Container)`
  width: 80%;
  margin: 50px auto 100px auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 360px;
    height: 310px;
    margin: 50px auto;
  }
`;

const Title = styled(Container)`
  color: ${colors.white};
  font-size: 80px;
  font-weight: ${fontWeights.bold};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
    margin-bottom: 10px;
    width: 85%;
  }
`;

const StyledWidget = styled(Widget)`
  width: 100%;
  height: 600px;
`;

const BlocksContentContainer = styled(Container)`
  width: 45%;
  p {
    font-weight: ${fontWeights.extraNormal};
    font-size: ${fontSizes.h4.default}px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    p {
      font-size: ${fontSizes.h4.mobile}px;
      line-height: 1.6;
    }
  }
`;

const AnimatedJeff = styled.p`
  margin: auto;
  margin-top: 20px;
  width: fit-content;
  @keyframes example {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: example;
  animation-duration: 8s;
  color: ${colors.tobeornottobe};
  opacity: 0;
`;

interface Data extends Query {
  altPrevious: SanityBlogInnerConnection;
  altNext: SanityBlogInnerConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: Query;
}

export default function BlogInnerPage({ data }: Props) {
  const { nodes } = data.allSanityBlogInner;
  const previousBlog = data.altPrevious.nodes;
  const nextBlog = data.altNext.nodes;
  const { slug, seo, title, heroContent, components, previewMedia } = nodes[0];
  const { isMobileWidth } = useCheckScreenWidth();

  console.log({ data });

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <BlogHero color={previewMedia[0].color}>
        <Container
          flexDirection="column"
          margin={isMobileWidth ? "auto auto 40px 45px" : "auto auto 115px 140px"}
        >
          <Title>{title}</Title>
          <BlocksContentContainer>
            <BlocksContent data={heroContent} />
          </BlocksContentContainer>
        </Container>
      </BlogHero>

      {components &&
        components.map(component => {
          if (component == null) return null;
          if (isSanityNoteComponent(component)) {
            return <NoteComponent data={component} key={component._key} />;
          }
          if (isSanityBlockContent(component)) {
            return (
              <Container
                width="55%"
                margin="50px auto"
                key={component._key}
                mobileWidth="80%"
                mobileMargin="30px auto 50px auto"
              >
                <BlocksContent data={component} overrideColor={previewMedia[0].color} />
              </Container>
            );
          }
          if (isSanityImageWithMeta(component)) {
            return (
              <ImageContainer key={component._key}>
                <Image data={component} objectFit="cover" width="100%" height="100%" />
              </ImageContainer>
            );
          }
          if (isSanityTypeForm(component)) {
            return (
              <Container key={component._key} width="100%" height="100%">
                <StyledWidget id={component?.formId as string} className="my-form" />
              </Container>
            );
          }
          return null;
        })}
    </div>
  );
}

export const query = graphql`
  query BlogInnerPageQuery($slug: String, $previous: String, $next: String) {
    allSanityBlogInner(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityBlogInner
      }
    }
    altPrevious: allSanityBlogInner(filter: { slug: { current: { eq: $previous } } }) {
      nodes {
        _id
        _key
        title
        slug {
          current
        }
        previewMedia {
          ... on SanityHexColors {
            ...sanityHexColors
          }
          ... on SanityImageWithMeta {
            ...sanityImageWithMeta
          }
        }
      }
    }
    altNext: allSanityBlogInner(filter: { slug: { current: { eq: $next } } }) {
      nodes {
        _id
        _key
        title
        slug {
          current
        }
        previewMedia {
          ... on SanityHexColors {
            ...sanityHexColors
          }
          ... on SanityImageWithMeta {
            ...sanityImageWithMeta
          }
        }
      }
    }
  }
`;
